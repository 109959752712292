p {
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0,0,0,.4);
}

.main {
  max-width: 1024px;
  text-align: center;
  margin: 0 auto;

  .lang {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;

    li {
      display: block;
      border-left: 1px solid black;

      &:first-child {
        border: 0px;
      }
    }

    a {
      padding: 8px;
      margin: 0 8px;
    }

    .active {
      font-weight: 700;
    }
  }

  .eyecatch {
    margin: 0 auto;
    position: relative;
    max-width: 643px;
    height: 643px;
    background: url("/images/Background.png") center no-repeat;
    background-size: 643px;

    .container {
      padding-top: 63px;
    }

    h1 {
      margin: 0;
      padding: 0;
      font-size: 20px;
      font-family: 'Roboto', sans-serif;
      font-weight: normal;

      span {
        color: white;
        background-color: black;
        padding: 4px 15px;
      }
    }

    .arImage {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      width: 240px;
      height: 240px;
      border-radius: 20px;
    }
  }

  .description {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.75;
    margin: 32px 0 0;
  }

  footer {
    font-family: 'Noto Sans JP', sans-serif;
    margin: 90px 0 60px;

    .staff {
      margin: 30px 0 60px;
      p {
        margin: 0 0 8px;
      }
    }

    a {
      padding: 4px;
    }
    img {
      width: 63px;
    }
  }

}
